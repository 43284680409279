import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import FooterWrapper, { List, ListItem } from './footer.style';
import LogoImage from 'common/assets/image/agency/logo.png';
import CopyrightWrapper from "../CopyrightsSection/copyrightSection.style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin, faPhone, faEnvelope, faClock } from "@fortawesome/free-solid-svg-icons";

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
          }
        },
        social_profile {
          id
          icon
          link
        }
      }
    }
  `);

  let Icons = [
    [
      faLocationPin,
      faPhone,
      faEnvelope
    ],
    [
      faClock
    ]
  ];

  return (
    <FooterWrapper id="footerSection">
      <Container>
        <Box className="row" {...row}>
          <Box className="boxFooterLogo" {...colOne}>
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="Sirvent Productes"
              logoStyle={logoStyle}
            />
            <hr className="hr-footer"></hr>
            <CopyrightWrapper className="copyright_section">
              <ul className="listSocialMediaFooter">
                {Data.agencyJson.social_profile.map((profile, index) => (
                    <li key={`profile_key_${index}`}>
                      <a href={profile.link} aira-label={profile.icon}>
                        <i className={profile.icon} />
                      </a>
                    </li>
                ))}
              </ul>
              <Text content="Copyrights 2023 @Sirvent Productes SL" />
            </CopyrightWrapper>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.agencyJson.menuWidget.map((widget, key) => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle}/>
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`list__item-${item.id}`} className="mt-3">
                      {item.url.length > 0 ? (
                          <a className="ListItem" href={item.url}>
                            {item.text}
                          </a>
                      ) : (
                          <span><FontAwesomeIcon icon={Icons[key][index]} size="lg" style={{ color: '#343d48' }} /><span className="ml-2 text-sm">{item.text}</span></span>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '35%', '26%'],
    mt: [0],
    mb: ['15px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '65%', '70%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '33.3%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
  },
  // Default logo size
  logoStyle: {
    width: '200px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#0f2137',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
